<template>
  <div class="entity-form">
    <div class="entity-form__body" v-if="isReady">
      <v-dialog v-model="autologinDialog" width="1000">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{ translations.labels.autologin_event_dialog }}
          </v-card-title>
          <v-row class="mx-auto">
            <v-col cols="8">
              <v-select
                v-model="selectedEventAutoLogin"
                name="idEvent"
                label="Event"
                hint="Select an event for which the code will be valid. if no event is selected, it will be valid for the default one."
                persistent-hint
                :items="events"
                item-text="label"
                item-value="value"
                return-object
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12">
              <div class="copyable-text-wrapper">
                <div
                  @click="copyAutoLoginToClipboard"
                  class="copyable-text wrapper-field"
                >
                  <v-text-field
                    v-model.trim="autoLoginURL"
                    name="autoLoginURL"
                    :label="translations.labels.user_form_autologinURL"
                    disabled
                    type="text"
                    outlined
                  />
                </div>
                <div @click="copyAutoLoginToClipboard" class="icon-wrapper">
                  <v-icon v-if="!feedbackIsVisible" right>
                    mdi-content-copy
                  </v-icon>
                </div>
              </div>
              <div v-show="feedbackIsVisible" class="feedback">Copied!</div>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeAutoLoginEventDialog">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="verificationTokenDialog" width="1000">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            {{ translations.labels.verificationToken_event_dialog }}
          </v-card-title>
          <v-row class="mx-auto">
            <v-col cols="8">
              <v-select
                v-model="selectedEventVerificationToken"
                name="idEvent"
                label="Event"
                hint="Select an event for which the token will be valid. if no event is selected, it will be valid for the default one."
                persistent-hint
                :items="events"
                item-text="label"
                item-value="value"
                return-object
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12">
              <div class="copyable-text-wrapper">
                <div
                  @click="copyVerificationTokenToClipboard"
                  class="copyable-text wrapper-field"
                >
                  <v-text-field
                    v-model.trim="verificationTokenURL"
                    name="verificationTokenURL"
                    :label="translations.labels.user_form_verificationTokenUrl"
                    disabled
                    type="text"
                    outlined
                  />
                </div>
                <div
                  @click="copyVerificationTokenToClipboard"
                  class="icon-wrapper"
                >
                  <v-icon v-if="!feedbackIsVisible" right>
                    mdi-content-copy
                  </v-icon>
                </div>
              </div>
              <div v-show="feedbackIsVisible" class="feedback">Copied!</div>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="closeVerificationTokenEventDialog"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <FormBody
        :form-id="getFormId()"
        :form-title="getFormTitle()"
        :model.sync="model"
        :actions="actions"
        :elements="decoratedElements"
        :columns="fieldColumns"
        :errors.sync="errors"
        :helpLink="helpLink"
        v-on="actionsHandlers"
      >
        <slot></slot>
      </FormBody>
    </div>
  </div>
</template>
<script>
import UserForm from "@/views/menu/user/userForm";
import Component, { mixins } from "vue-class-component";
import { toastService } from "@/services/toastService.js";
import _env from "@/plugins/env";

@Component({
  inject: {
    userService: "userService",
    eventService: "eventService",
    visitorLevelService: "visitorLevelService",
    visitorPermissionService: "visitorPermissionService",
    platformconfigurationService: "platformConfigurationService",
  },
})
export default class UserEdit extends mixins(UserForm) {
  formId = "edit-user-form";
  isSaving = false;
  feedbackIsVisible = false;
  internal_events = [];
  selectedEventAutoLogin = {};
  selectedEventVerificationToken = {};
  mainEventCode = "";

  get decoratedElements() {
    let attendancesField = this.insertAfter(
      this.elements,
      this.attendancesField(false),
      "publicBusinessData"
    );

    if (this.currentUser.groups.find((element) => element == "SuperAdmins")) {
      let resetPasswordField = this.insertAfter(
        attendancesField,
        this.resetPasswordField(),
        "publicBusinessData"
      );

      return resetPasswordField;
    }

    return attendancesField;
  }

  copyAutoLoginToClipboard() {
    navigator.clipboard.writeText(this.autoLoginURL);

    this.showFeedBack();
  }

  copyVerificationTokenToClipboard() {
    navigator.clipboard.writeText(this.verificationTokenURL);

    this.showFeedBack();
  }

  closeAutoLoginEventDialog() {
    this.autologinDialog = false;
  }

  closeVerificationTokenEventDialog() {
    this.verificationTokenDialog = false;
  }

  showFeedBack() {
    this.feedbackIsVisible = true;

    setTimeout(() => {
      this.feedbackIsVisible = false;
    }, 2000);
  }

  getFormTitle() {
    return this.translations.pageTitles.users_edit;
  }

  get events() {
    return this.internal_events.map((i) => {
      return {
        languages: i.languages,
        label: i.code,
        value: i.id,
      };
    });
  }

  get fieldColumns() {
    return 3;
  }

  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        handle: this.save,
        disabled: () => {
          return this.isSaving;
        },
      },
      {
        id: "send-email",
        type: "submit",
        text: this.translations.labels.button_sendRegistrationEmail,
        leftIcon: "mdi-email-edit-outline",
        handle: this.sendEmail,
        disabled: () => {
          return this.model.isVerified || this.isSaving;
        },
      },
      {
        id: "refreshVerificationToken",
        type: "submit",
        text: this.translations.labels.button_refreshVerificationToken,
        leftIcon: "mdi-rotate-3d-variant",
        handle: this.refreshVerificationToken,
        disabled: () => {
          return this.isSaving;
        },
      },
      {
        id: "generateAutoLogin",
        text: this.translations.labels.button_generateAutoLogin,
        leftIcon: "mdi-account-convert",
        handle: this.generateAutoLoginCode,
        skipValidations: true,
        needConfirmation: this.model.autoLoginCode ? true : false,
        confirmationMessage:
          "All the links with the current code will be invalidated if you proceed.Do you confirm ?",
        disabled: () => {
          return this.isSaving;
        },
      },
    ];
  }

  async generateAutoLoginCode() {
    try {
      this.isSaving = true;

      await this.userService.generateAutoLogin(this.model.id);
      this.isSaving = false;

      this.loadData();
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  async sendEmail() {
    try {
      this.isSaving = true;
      await this.userService.sendCompleteRegistrationEmail(
        this.model.id
      );

      toastService.success(
        this.translations.success.user_sendRegistrationEmail
      );
      this.isSaving = false;
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  async refreshVerificationToken() {
    try {
      this.isSaving = true;

      await this.userService.refreshVerificationToken(
        this.model.id
      );

      this.isSaving = false;
      this.loadData();
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  async save(passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }

    try {
      this.isSaving = true;
      this.model.idLanguage = this.model.language.idLanguage;
      this.model.cultureCode = this.model.language.cultureCode;
      this.model.expirationDate =
        this.model.expirationDate != "" ? this.model.expirationDate : null;

      this.model.customFields = this.formatCustomFieldsToSave(
        this.model.userCustomFields
      );

      await this.userService.update(this.model.id, this.model);

      this.isSaving = false;
      this.$router.push("/user/list");
      toastService.success(this.translations.success.user_edit);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  get currentUser() {
    return this.$store.state.authStore.currentUser;
  }

  get autoLoginEventCode() {
    return this.selectedEventAutoLogin?.label;
  }

  get verificationTokenEventCode() {
    return this.selectedEventVerificationToken?.label;
  }

  get autoLoginURL() {
    let eventCode = this.mainEventCode;

    if (
      this.autoLoginEventCode !== undefined &&
      this.autoLoginEventCode !== null
    ) {
      eventCode = this.autoLoginEventCode;
    }

    return this.model.autoLoginCode
      ? `${_env("VUE_APP_VIRTUAL_PLACE_URL")}/event-${eventCode}?autologinCode=${this.model.autoLoginCode}`
      : "";
  }

  get verificationTokenURL() {
    let eventCode = this.mainEventCode;

    if (
      this.verificationTokenEventCode !== undefined &&
      this.verificationTokenEventCode !== null
    ) {
      eventCode = this.verificationTokenEventCode;
    }

    return this.model.verificationToken
      ? `${_env("VUE_APP_VIRTUAL_PLACE_URL")}/event-${eventCode}/auth/completeRegistration?verificationToken=${this.model.verificationToken}&passwordUpdate=true`
      : "";
  }

  async created() {
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.users_edit
    );
    this.internal_events = await this.eventService.list();
  }

  async loadData() {
    this.model = await this.userService.read(this.$route.params.id);
    this.model.language = {
      idLanguage: this.model.idLanguage,
      cultureCode: this.model.cultureCode,
    };
    this.model.idGroupList = this.model.groups.map((g) => g.id);

    this.setVisitorLevels(
      await this.visitorLevelService.enabledUserLevels(this.model.id)
    );
    this.model.visitorLevels = this.currentVisitorLevelsValue();

    this.model.userCustomFields = this.prepareCustomFieldsModel(
      this.model.customFields
    );

    this.emailSended = false;
    await this.loadAttendances();
    this.isReady = true;
  }
  async afterCreate() {
    this.loadData();
    this.mainEventCode =
      await this.platformconfigurationService.mainEventCode();
  }
}
</script>
<style lang="scss" scoped>
.icon-wrapper {
  cursor: pointer;
  display: flex;
}

.copyable-text {
  cursor: pointer;
  width: 95%;
}

.feedback {
  background-color: #8bd499;
  font-size: 18px;
  color: #fff;
  border-radius: 5px;
  padding: 3px;
}

.copyable-text-wrapper {
  display: inline-flex;
  width: 100%;
}
</style>